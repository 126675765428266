<template>
  <div class="manager-body">
    <main>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12" class="p-2">
                <b-overlay :show="overlay">
                  <b-card v-if="openForm" style="margin-bottom: 10px">
                    <b-card-title>
                      Add Template
                      <b-button @click="openForm = false" size="sm" class="float-right" variant="default">Close</b-button>
                    </b-card-title>
                    <b-form @submit.prevent="saveUserTemplate($route.params.id)">
                      <b-form-group
                          id="input-group-1"
                          label="Body"
                          label-for="textarea"
                      >
                        <b-form-textarea
                            id="textarea"
                            v-model="templateBody"
                            ref="templateBody"
                            placeholder="Type template body here"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <b-form-text v-if="templateBodyValidation.length > 0">{{ templateBodyValidation }}</b-form-text>
                      </b-form-group>
                      <div class="float-right">
                        <b-button type="button" @click="variables.modal = true">Insert Variables</b-button>&nbsp;
                        <b-button type="submit" variant="primary">Save Template</b-button>
                      </div>
                    </b-form>
                  </b-card>
                  <b-card>
                    <b-card-title>
                      User Templates
                      <b-button v-if="!openForm" @click="openForm = true" size="sm" class="float-right" variant="primary">Add Template</b-button>
                    </b-card-title>
                    <b-table striped hover :items="templates">
                      <template #cell(action)="row">
                        <b-button-group size="sm">
                          <b-button @click="getTemplate(row.item.id)">
                            <b-icon-pencil-square />
                          </b-button>
                          <b-button @click="deleteTemplate(row.item.id)"><b-icon-trash/></b-button>
                        </b-button-group>
                      </template>
                    </b-table>
                  </b-card>
                </b-overlay>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <b-modal v-model="variables.modal" hide-footer title="Add Variable">
      <p><small>Please Specify the type & name of the variable you want to add. The variable content has to be specified at the sam time of sending. You can use variables to send customized message content using a template. Note that templates with only a variable as the main content of the message will be rejected</small></p>
      <b-form @submit.prevent="addVariable">
        <b-form-group id="input-group-3" label="Type" label-for="input-3">
          <b-form-select
              id="input-3"
              v-model="variables.type"
              :options="variables.options"
              required
          ></b-form-select>
        </b-form-group>
        <b-form-group id="input-group-2" label="Tag Name" label-for="input-2">
          <b-form-input
              id="input-2"
              v-model="variables.title"
              placeholder="Tag name"
              required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" class="float-right">Add</b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import router from "../../router";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      overlay: true,
      session: {},
      bus: new Vue(),
      templates: [],
      templateBody: '',
      templateBodyValidation: '',
      openForm: false,
      fields: [],
      variables: {
        modal: false,
        type: 1,
        key: '',
        title: '',
        options: [
          {
            text: "text",
            value: 1
          },
          {
            text: "date",
            value: 2
          },
          {
            text: "time",
            value: 3
          }
        ]
      }
    };
  },
  mounted() {
    if (localStorage.getItem("session")  !== null) {
      this.session = JSON.parse(localStorage.getItem('session'))
    } else {
      router.push('/');
    }
    this.getTemplates()
  },
  methods: {
    getTemplates: function () {
      this.$http.get('/message/user/templates').then(response => {
        this.overlay = false;
        this.templates = []
        if(response.data.result) {
          response.data.data.map((template) => {
            this.templates.push({
              id: template.id,
              templateBody: template.template,
              createdAt: template.created_at,
              action: template.id
            })
          })
        } else {
          console.error("Failed to update tags");
        }
      });
    },
    deleteTemplate: function (id) {
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete a template.', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if(value) {
              this.overlay = true;
              this.$http.get(`/message/user/templates/${id}/delete`).then(response => {
                this.overlay = false;
                if(response.data.result) {
                  this.$bvToast.toast(`Template was deleted`, {
                    variant: 'info',
                    title: 'Data Deleted'
                  })
                  let templateIndex = this.templates.findIndex(x => x.id === id);
                  this.templates.splice(templateIndex, 1)
                } else {
                  console.error("Failed to update tags");
                }
              });
            }
          })
    },
    getTemplate: function (id) {
      this.overlay = true
      this.$http.get(`/message/user/templates/${id}`).then(response => {
        this.overlay = false
        this.openForm = true
        if(response.data.result) {
          this.fields = response.data.data.fields
          this.templateBody = response.data.data.template
        }
      })
    },
    saveUserTemplate: function (id) {
      this.overlay = true;
      this.openForm = false;
      if( this.templateBody === '') {
        this.templateBodyValidation = "Body cannot be empty";
      } else {
        this.templateBodyValidation = ""
        let formData = {
          template: this.templateBody,
          fields: this.fields
        }
        this.fields.map((field, index) => {
          if( this.templateBody.indexOf(field.key) === -1 ) {
            this.fields.splice(index, 1)
          }
        })
        if(id !== 0) {
          formData['id'] = id
        }
        this.$http.post('/message/user/templates/save', formData).then(response => {
          this.overlay = false;
          if(response.data.result) {
            this.$bvToast.toast(`Template was created successfully`, {
              variant: 'info',
              title: 'Data Saved'
            })
            this.getTemplates()
          } else {
            console.error("Failed to update tags");
          }
        });
      }
    },
    addVariable: function () {
      let key = this.variables.title.replace(/[^A-Z0-9]/ig, "").toLowerCase()
      key = `<${key}>`
      this.fields.push({
        key: key,
        value: "",
        title: this.variables.title,
        type: this.variables.type
      })
      let textarea = this.$refs.templateBody,
          cursorPosition = textarea.selectionStart,
          textBefore = this.templateBody.substring(0, cursorPosition),
          textAfter = this.templateBody.substring(cursorPosition, this.templateBody.length);
      this.templateBody = textBefore + key + textAfter
      this.variables.modal = false;
      this.variables.type = 1;
      this.variables.key = '';
      this.variables.title = '';
    }
  }
}
</script>
<style>
.manager-body {
  background: #F3F5F8;;
}
.menubar {
  background: white;
}
</style>
