<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <b-overlay :show="overlay">
                    <div class="chart-container manager-shadow mt-3">
                        <h5>Conversation Assignments</h5>
<!--                        <div class="mb-2">
                            <b-button-group>
                                <b-button @click="selectAllRows">Select All</b-button>
                                <b-button @click="clearSelected">Clear</b-button>
                                <b-button v-if="datatable.selected.length > 0" variant="success" @click="modal=true">Assign Selected ({{ datatable.selected.length }})</b-button>
                            </b-button-group>
                        </div>-->
                        <b-table
                            :busy.sync="isBusy"
                            :current-page="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :fields="datatable.fields"
                            :items="list"
                        ></b-table>
                        <b-pagination
                            v-model="pagination.currentPage"
                            :total-rows="pagination.totalRows"
                            :per-page="pagination.perPage"
                            align="fill"
                            size="sm"
                            class="my-0"
                        ></b-pagination>
                    </div>
                </b-overlay>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import router from "../router";

export default {
    name: "Conversations",
    data() {
        return {
            isBusy: false,
            overlay: false,
            pagination: {
                currentPage: 0,
                lastPage: null,
                perPage: 10,
                totalRows: null
            },
            datatable: {
                modes: ['multi', 'single', 'range'],
                selectMode: 'multi',
                selected: [],
                fields: [
                    {
                        key: 'notification',
                        label: 'Log',
                        sortable: false
                    },
                    {
                        key: 'conversation.influencer.name',
                        label: "Conversation",
                        sortable: false
                    },
                    {
                        key: 'requested_to.name',
                        label: 'Requested To',
                        sortable: false
                    },
                    {
                        key: 'requested_by.name',
                        label: 'Requested By',
                        sortable: false
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: false
                    },
                    {
                        key: 'updated_at',
                        sortable: false
                    },
                ]
            }
        }
    },
    mounted() {
        if (localStorage.getItem("session")  !== null) {
            this.session = JSON.parse(localStorage.getItem('session'))
        } else {
            router.push('/');
        }
    },
    methods: {
        /*list() {
            this.overlay = true;
            this.$http.post('/manager/conversations', {}).then(response => {
                this.overlay = false;
                if(response.data.result) {
                    this.conversations = response.data.data;
                    this.$refs.selectableTable.clearSelected();
                }
            }).catch( error => {
                this.overlay = false;
                this.errorHandler(error)
            });
        },*/
        list (ctx, callback) {
            // Here we don't set isBusy prop, so busy state will be
            // handled by table itself
            // this.isBusy = true
            //let nextPage = this.pagination.currentPage + 1;
            this.$http.post(`/conversation/assignment/list?page=${ctx.currentPage}&per_page=${ctx.perPage}`).then( response => {
                const items = response.data.data.data
                this.pagination = {
                    currentPage: response.data.data.current_page,
                    lastPage: response.data.data.last_page,
                    perPage: response.data.data.per_page,
                    totalRows: response.data.data.total
                };
                callback(items)
            }).catch( error => {
                this.errorHandler(error);
                callback([]);
            })
        },
    }
}
</script>

<style scoped>

</style>