<template>
  <b-overlay :show="assignedContactsData == null">
    <div class="chart-container manager-shadow">
      <h5>Assigned Contacts</h5>
      <b-table striped hover :style="'height: 254px'" :items="assignedContactsData" :sticky-header="true" :fields="fields"></b-table>
    </div>
  </b-overlay>
</template>

<script>
export default {
  name: "AssignedContacts",
  data() {
    return {
      assignedContactsData: null,
      fields: [
        {
          key: 'agent_name',
          sortable: true
        },
        {
          key: 'assigned_contact_count',
          label: 'Assigned Contacts',
          sortable: true,
          sortKey: true
        }
      ]
    }
  },
  mounted() {
    this.assignedContacts()
  },
  methods: {
    assignedContacts: function () {
      this.$http.post('/analytics/agent/assigned').then(response => {
        if(response.data.result) {
          this.assignedContactsData = response.data.data
        } else {
          console.error("Failed to update tags");
        }
      });
    },
  }
}
</script>

<style scoped>

</style>