<template>
  <article>
    <section>
<!--      <header class="panel-header">
        <div class="title">
          <h1>Manager Home</h1>
          <span id="filter-representation">{{ dataFilters.availableFor }}</span>
        </div>
      </header>-->
      <div class="row">
        <div class="col-sm-6 manager-box p-2">
          <div class="dash-box d-flex justify-content-center align-items-center manager-shadow">
            <b-icon icon="people-fill" scale="2"></b-icon>
            <span class="pl-2 font-weight-bold">Manage<br/>Users</span>
          </div>
        </div>
        <router-link to="/manager/conversations" tag="div" class="col-sm-6 manager-box p-2">
          <div class="dash-box d-flex justify-content-center align-items-center manager-shadow">
            <b-icon icon="telephone-fill" scale="2"></b-icon>
            <span class="pl-2 font-weight-bold">Manage<br/>Contacts</span>
          </div>
        </router-link>
        <div class="col-sm-6 manager-box p-2">
          <div class="dash-box d-flex justify-content-center align-items-center manager-shadow">
            <b-icon icon="tags-fill" scale="2"></b-icon>
            <span class="pl-2 font-weight-bold">Manage<br/>Tags</span>
          </div>
        </div>
        <div class="col-sm-6 manager-box p-2">
          <div class="dash-box d-flex justify-content-center align-items-center manager-shadow">
            <b-icon icon="bookmarks-fill" scale="2"></b-icon>
            <span class="pl-2 font-weight-bold">Manage<br/>Labels</span>
          </div>
        </div>
      </div>
      <b-row class="prospects">
        <b-col cols="12" class="p-2">
          <b-list-group v-for="agent in agents" :key="agent.id">
            <b-list-group-item class="d-flex align-items-center" button>
<!--              <b-avatar class="mr-3"></b-avatar>-->
                <img :src="agent.display_picture"/>
                <span class="mr-auto">{{agent.name}}</span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </section>
  </article>
</template>

<script>

//import InfiniteLoading from "vue-infinite-loading";
//import Vue from "vue";

export default {
  name: "ManagerSidebarComponent",
  props: ['bus'],
  components: {},
  data() {
    return {
      session: {},
        agents: []
    }
  },
  mounted() {
    if (localStorage.getItem("session")  !== null) {
      this.session = JSON.parse(localStorage.getItem('session'))
    }
    this.getAgentList()
  },
  methods: {
    getAgentList: function () {
        this.$http.get('/agent/list').then( response => {
            if(response.data.result) {
                this.agents = response.data.data
            } else {
                this.toast("No users")
            }
        }).catch( error => this.errorHandler( error ))
    }
  }
}
</script>

<style scoped>
.prospects {
  height: calc(100vh - 200px);
  overflow: scroll;
}
.prospects img {
    border-radius: 20px;
    width: 40px;
    margin: 0 5px;
    border: 2px solid #ccc;
}
</style>
