import { Bar,mixins } from 'vue-chartjs'
const { reactiveProp } = mixins


export default {
    extends: Bar,
    mixins: [reactiveProp],
    mounted () {
        // Overwriting base render method with actual data.
        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio:false,
            cutoutPercentage: 80,
            legend: {
                display: true,
            },
            tooltips: {
                titleFontSize: 13,
                bodyFontSize: 13,
            },
            scales: {
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        //min: 0,
                        //max: 60,
                    },
                }],
                yAxes: [
                    {
                        //stacked: true,
                        barThickness: 8,
                        curvature: 5,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            min: 0,
                        }
                    },
                ],
            },
        })
    }
}