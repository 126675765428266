<template>
  <div class="manager-body">
    <main>
      <b-container v-if="session.role !== 'Manager'" fluid>
        <b-row>
          <b-col cols="12" class="text-center">
            <h4>Insufficient access <router-link to="/"> Go Back</router-link></h4>
          </b-col>
        </b-row>
      </b-container>
      <b-container v-if="session.role === 'Manager'" fluid>
        <b-row>
          <b-col cols="3">
            <manager-sidebar-component :bus="bus"/>
          </b-col>
          <b-col cols="9">
            <analytics></analytics>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
import Vue from "vue";
import ManagerSidebarComponent from "../components/ManagerSidebarComponent";
import Analytics from "../components/Analytics";
import router from "../router";

export default {
  name: 'App',
  components: {
    Analytics,
    ManagerSidebarComponent
  },
  data() {
    return {
      session: {},
      bus: new Vue(),
    };
  },
  mounted() {
    if (localStorage.getItem("session")  !== null) {
      this.session = JSON.parse(localStorage.getItem('session'))
    } else {
      router.push('/');
    }
  }
}
</script>
<style>
.manager-body {
  background: #F3F5F8;;
}
.menubar {
  background: white;
}
</style>
