<template>
    <b-container fluid>
        <b-row>
            <b-col cols="12">
                <b-overlay :show="overlay">
                    <div class="chart-container manager-shadow mt-3">
                        <h5>Conversation Management</h5>
                        <div class="mb-2">
                            <b-button-group>
                                <b-button @click="selectAllRows">Select All</b-button>
                                <b-button @click="clearSelected">Clear</b-button>
                                <b-button v-if="datatable.selected.length > 0" variant="success" @click="modal=true">Assign Selected ({{ datatable.selected.length }})</b-button>
                            </b-button-group>
                        </div>
                        <b-table hover
                                 :sticky-header="'600px'"
                                 :items="conversations"
                                 :fields="datatable.fields"
                                 :select-mode="datatable.selectMode"
                                 responsive="sm"
                                 ref="selectableTable"
                                 selectable
                                 @row-selected="onRowSelected"
                        ></b-table>
                    </div>
                </b-overlay>
            </b-col>
        </b-row>
        <b-modal v-model="modal" hide-footer title="Conversation Assignment">
            <b-overlay :show="modalOverlay">
                <form @submit.prevent="assign" class="user-tag-ct show">
                    <b-form-select v-model="assignTo" :options="users" size="sm" class="mt-1"></b-form-select>
                    <div class="mt-3 mb-2">
                        <p>Selected Conversations:<p/>
                        <b-list-group class="mt-1" v-for="(contact, i) in datatable.selected" :key="i">
                            <b-list-group-item class="d-flex align-items-center">
                                <span class="mr-auto">{{ contact.influencer.name }}</span>
                                <h6 v-if="contact.user_id !== null"><b-badge>{{ contact.user.name }}</b-badge></h6>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                    <b-button type="button" @click="assign" class="btn btn-primary">Apply</b-button>
                </form>
            </b-overlay>
        </b-modal>
    </b-container>
</template>

<script>
import router from "../router";

export default {
    name: "Conversations",
    data() {
        return {
            overlay: false,
            modalOverlay: false,
            modal: false,
            assignTo: null,
            session: {},
            conversations: [],
            users: [{ value: null, text: 'Select Agent' }],
            datatable: {
                modes: ['multi', 'single', 'range'],
                selectMode: 'multi',
                selected: [],
                fields: [
                    {
                        key: 'influencer.name',
                        label: "Contact Name",
                        sortable: true
                    },
                    {
                        key: 'influencer.phone',
                        label: 'Phone',
                        sortable: true
                    },
                    {
                        key: 'user.name',
                        label: 'Assigned To',
                        sortable: true,
                        sortKey: true
                    },
                    {
                        key: 'updated_at',
                        sortable: true
                    },
                ]
            }
        }
    },
    mounted() {
        if (localStorage.getItem("session")  !== null) {
            this.session = JSON.parse(localStorage.getItem('session'))
        } else {
            router.push('/');
        }
        this.list();
        this.$http.post('/user/list', {}).then(response => {
            this.overlay = true;
            if(response.data.result) {
                response.data.data.map( user => {
                    this.users.push({ value: user.id, text: user.name })
                });
            }
        }).catch( error => {
            this.overlay = false;
            this.errorHandler(error)
        });
    },
    methods: {
        list() {
            this.overlay = true;
            this.$http.post('/manager/conversations', {}).then(response => {
                this.overlay = false;
                if(response.data.result) {
                    this.conversations = response.data.data;
                    this.$refs.selectableTable.clearSelected();
                }
            }).catch( error => {
                this.overlay = false;
                this.errorHandler(error)
            });
        },
        onRowSelected(items) {
            this.datatable.selected = items
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },
        assign() {
            console.info(this.assignTo, this.datatable.selected);
            let confirmMessage = "Are you sure, you want to change assignment for selected conversations?";
            if( this.assignTo == null ) {
                confirmMessage = "Are you sure, you want to remove assignment from selected conversations?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage)
                .then(v => {
                    if(v) {
                        this.modalOverlay = true;
                        let conversationIds = [];
                        this.datatable.selected.map( selected => conversationIds.push(selected.id) )
                        this.$http.post('/manager/conversations/assign', {
                            conversationIds: conversationIds,
                            assignTo: this.assignTo
                        }).then(response => {
                            this.modalOverlay = false;
                            if(response.data.result) {
                                this.$bvToast.toast(`Conversation assignment changed successfully`, {
                                    variant: 'success',
                                    title: 'Success'
                                });
                                this.modal = false;
                                this.list();
                            } else {
                                this.modalOverlay = false;
                                this.$bvToast.toast(`Conversation assignment failed`, {
                                    variant: 'danger',
                                    title: 'Failed'
                                });
                            }
                        }).catch( error => {
                            this.modalOverlay = false;
                            this.errorHandler(error)
                            this.$bvToast.toast(`Conversation assignment failed`, {
                                variant: 'danger',
                                title: 'Failed'
                            });
                            this.modal = false;
                        });
                    }
                })
                .catch(err => {
                    // An error occurred
                    console.error(err)
                    this.errorHandler(err);
                });
        }
    }
}
</script>

<style scoped>

</style>