<template>
  <b-row>
    <b-col cols="6" class="p-2">
      <assigned-contacts />
    </b-col>
    <b-col cols="6" class="p-2">
      <TimeSpent/>
    </b-col>
    <b-col cols="12" class="p-2">
      <b-overlay :show="dataCollection == null">
        <div class="chart-container manager-shadow" style="min-height: 390px;">
          <b-row class="m-3">
            <b-col cols="6">
              <b-form-select
                  id="input-3"
                  v-model="form.chart_id"
                  :options="chartTypesData"
                  @change="mixCharts"
              ></b-form-select>
            </b-col>
            <b-col cols="3">
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <date-range-picker
                    v-model="dateRange"
                    :opens="'left'"
                    @update="dateChange"
                ></date-range-picker>
              </b-input-group>
            </b-col>
            <b-col cols="3">
              <select2 v-if="!multipleAgents.includes(form.chart_id)" v-model="form.agent_id" :options="agentListData" @change="mixCharts" @select="mixCharts"/>
              <select2-multiple-control v-if="multipleAgents.includes(form.chart_id)" v-model="form.agent_id" :options="agentListData" @change="mixCharts" @select="mixCharts" multiple/>
            </b-col>
          </b-row>
          <bar-chart v-if="dataCollection && form.chart_id !== 5" :chart-data="dataCollection" :style="{height: '300px'}"></bar-chart>
          <b-row v-if="form.chart_id === 5">
            <b-col sm="6" md="12">
              <b-form-group
                  label-cols-sm="6"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="agentAverageResponse.filter"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!agentAverageResponse.filter" @click="agentAverageResponse.filter = ''">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-table v-if="form.chart_id === 5"
                   striped hover :style="'height: 254px'"
                   :items="agentAverageResponse.data"
                   :fields="agentAverageResponse.fields"
                   :filter="agentAverageResponse.filter"
                   :sticky-header="true" show-empty>
            <template #cell(office_hours)="row">
              {{ getDuration(row.item.office_hours) }}
            </template>
            <template #cell(outside_office_hours)="row">
              {{ getDuration(row.item.outside_office_hours) }}
            </template>
          </b-table>
        </div>
      </b-overlay>
    </b-col>
  </b-row>
</template>
<script>
import BarChart from "../charts/BarChart";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment';
import AssignedContacts from "./Analytics/AssignedContacts";
import Select2 from 'v-select2-component'
import Select2MultipleControl from 'v-select2-multiple-component';
import TimeSpent from "./Analytics/TimeSpent";
export default {
  name: "Analytics",
  components: {
    TimeSpent,
    AssignedContacts,
    BarChart,
    DateRangePicker,
    Select2,
    Select2MultipleControl
  },
  data () {
    return {
      agentSelection: true,
      multipleAgents: [1,2,3,8,11],
      dateRange: {
        startDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().clone().endOf('month').format('YYYY-MM-DD'),
        dates: null,
      },
      form: {
        email: '',
        name: '',
        agent_id: null,
        chart_id: 1,
      },
      foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
      dataCollection: null,
      chartTypesData: [],
      agentListData: [],
      chartOptions: {},
      agentAverageResponse: {
        data: [],
        filter: '',
        fields: [
          {
            key: 'contact_name',
            sortable: true
          },
          {
            key: 'office_hours',
            sortable: true
          },
          {
            key: 'outside_office_hours',
            sortable: true
          },
        ]
      }
    };
  },
  mounted() {
    this.chartTypes();
    this.agentList();
  },
  methods: {
    dateChange: function () {
      this.mixCharts();
    },
    getDuration: (time) => {
      return moment.duration(time).humanize()
    },
    mixCharts: function () {
      if(!this.agentSelection) {
        this.toast("Only 5 agents data can be displayed");
        return false;
      }
      this.dataCollection = null;
      let request = {
        chart_type:this.form.chart_id,
        start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
      }
      request.agent_id = this.form.agent_id
      this.$http.post('/analytics', request).then(response => {
        this.dataCollection = {
          labels: [],
          datasets: [{
            label: 'Contacts',
            backgroundColor: '#32E188',
            data: []
          }]
        }
        if(response.data.result) {
          if(this.form.chart_id === 5) {
            this.agentAverageResponse.data = response.data.data
          } else {
            let labelKey = 'date',
                dataKey = 'count',
                labelText = 'Contacts',
                divided_by = 1;
            switch (this.form.chart_id) {
              case 11:
                labelText = 'Seconds';
                break;
              case 8:
                labelText = 'Tags';
                break;
              case 7:
                dataKey = 'messages';
                labelText = 'Messages';
                this.dataCollection.datasets[1] = {
                  label: 'Unique Contacts',
                  backgroundColor: '#000000',
                  data: []
                }
                break;
              case 5:
                //divided_by = 3600;
                labelKey = 'contact_name';
                dataKey = 'office_hours';
                labelText = 'Office Hours';
                this.dataCollection.datasets[1] = {
                  label: 'Outside Office Hours',
                  backgroundColor: '#000000',
                  data: []
                }
                break;
              case 6:
                //divided_by = 60;
                labelKey = 'name';
                dataKey = 'time_taken';
                labelText = 'Minutes';
                break;
            }
            let data = response.data.data;
            if(this.multipleAgents.includes(this.form.chart_id)) {
              let users = [],
                  labels = [],
                  newCollection = [],
                  colourCodes = ["#C57550","#CCA966","#83A236","#1B5234","#D098DD"];
              data.map( v => {
                if(labels.indexOf(v.date) === -1) {
                  labels.push(v.date)
                }
                if(!newCollection[v.user_id]) {
                  newCollection[v.user_id] = {};
                }

                if(this.form.chart_id === 11) {
                  newCollection[v.user_id][v.date] = parseFloat(v.count / 60).toFixed(2);
                  users[v.user_id] = v.user.name
                } else {
                  newCollection[v.user_id][v.date] = v.count;
                  users[v.user_id] = v.agent_name;
                }

              });

              this.dataCollection = {
                labels: labels,
                datasets: []
              };
              let cnt = 0;
              users.map( (name, user_id) => {
                let counts = [], totalCounts = 0;

                labels.map( date => {
                    let contactedCount = !newCollection[user_id][date] ? 0 : newCollection[user_id][date];
                    counts.push(Math.round(contactedCount));
                    totalCounts += parseFloat(contactedCount);
                });
                name = `${name} (${Math.round(totalCounts)})`
                this.dataCollection.datasets.push({
                  backgroundColor: colourCodes[cnt],
                  label: name,
                  data: counts
                });
                cnt++;
              });
              if(this.dataCollection.datasets.length === 5) {
                this.agentSelection = false
              } else {
                this.agentSelection = true
              }
            } else {
                let uniqueMessagesCount = 0, messagesCount = 0;
              for (let i=0;i<data.length; i++) {
                this.dataCollection.labels.push(data[i][labelKey])
                this.dataCollection.datasets[0].data.push(data[i][dataKey] / divided_by)
                this.dataCollection.datasets[0].label = labelText;
                if(this.form.chart_id === 7) {
                    uniqueMessagesCount += data[i]['unique'];
                    messagesCount += data[i]['messages'];
                    this.dataCollection.datasets[0].label = `${labelText} (${messagesCount})`;
                  this.dataCollection.datasets[1].data.push(data[i]['unique'])
                  this.dataCollection.datasets[1].label = `Unique contacts (${uniqueMessagesCount})`
                }
              }
            }
          }
        } else {
          console.error("Failed to update tags");
        }
      }).catch( error => this.errorHandler(error) )
    },
    chartTypes: function () {
      this.$http.get('/analytics/types').then(response => {
        if(response.data.result) {
          for(let i=0;i<response.data.data.length;i++) {
            this.chartTypesData.push({
              text: response.data.data[i].title,
              value: response.data.data[i].id
            });
          }
        } else {
          console.error("Failed to update tags");
        }
      })
          .catch( error => this.errorHandler(error) );
    },
    agentList: function () {
      this.$http.post('/user/list').then(response => {
        if(response.data.result) {
          this.form.agent_id = response.data.data[0].id
          this.mixCharts();
          for(let i=0;i<response.data.data.length;i++) {
            this.agentListData.push({
              text: response.data.data[i].name,
              id: response.data.data[i].id
            });
          }
        } else {
          console.error("Failed to update tags");
        }
      }).catch( error => this.errorHandler(error) );
    },
  }
}
</script>