<template>
  <b-overlay :show="timeSpent == null">
    <div class="chart-container manager-shadow">
      <h5>Time Spent (Total)</h5>
      <b-table striped hover :style="'height: 254px'" :items="timeSpent" :sticky-header="true" :fields="fields"></b-table>
    </div>
  </b-overlay>
</template>

<script>
export default {
  name: "TimeSpent",
  data() {
    return {
      timeSpent: [],
      fields: [
        {
          key: 'agent',
          label: 'Agent',
          sortable: true
        },
        {
          key: 'timespent',
          label: 'Time Spent (hh:mm:ss)',
          sortable: true,
          sortKey: true
        }
      ]
    }
  },
  mounted() {
    this.$http.get('/analytics/agent/timespent').then( response => {
      if(response.data.result) {
        response.data.data.map (timespent => {
          this.timeSpent.push({
            agent: timespent.user.name,
            timespent: this.secondsToHms(timespent.timespent)
          })
        });
        //this.timeSpent = response.data.data
      } else {
        console.error("Failed to update tags");
      }
    });
  },
  methods: {
    secondsToHms: (seconds) => {
      seconds = Number(seconds);
      let h = Math.floor(seconds / 3600),
          m = Math.floor(seconds % 3600 / 60),
          s = Math.floor(seconds % 3600 % 60),
          hDisplay = h > 0 ? ('0' + h).slice(-2) + ":" : "00:",
          mDisplay = m > 0 ? ('0' + m).slice(-2) + ":" : "00:",
          sDisplay = s > 0 ? ('0' + s).slice(-2) : "00";
      return hDisplay + mDisplay + sDisplay;
    }
  }
}
</script>

<style scoped>

</style>